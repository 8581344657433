import { jhe } from '../../src/js/default';
import $ from 'jquery';

// This is necessary because bootstrap-button expects jQuery on window, but we don't want to
// require globalizeForLegacy.js to put it there.
window.jQuery = $;
require('../../libs/bootstrap-3.4.1/js/bootstrap.js');
require('../../libs/bootstrap-assorted/bootstrap-button.js');
require('../../libs/oasis/oasis.min');

function showError(errorMsg) {
  const $errorBlock = $('#flash_message');

  if ($errorBlock.length) {
    $errorBlock.addClass('alert-error').show().find('p').text(jhe(errorMsg));
  } else {
    buildFlashMessage(errorMsg, '.layout-login nocache');
    $('#flash_message').show();
  }
}

function hideError() {
  const $errorBlock = $('#flash_message');

  if ($errorBlock.length) {
    $errorBlock.hide().find('p').text('');
  }
}

function buildFlashMessage(message, location) {
  let html = '';
  html += '<div id="flash_message" class="alert alert-error" style="display:flex">';
  html += '<span class="icon"> </span>';
  html += `<p>${jhe(message)}</p>`;
  html += '<a class="close" data-dismiss="alert" href="#">×</a>';
  html += '</div>';

  if (typeof location !== 'undefined') {
    $(location).html(html);
  } else {
    return html;
  }
}

function init() {
  $(document).ready(() => {
    const $wrapper = $('.login-container');
    const $submitUsername = $wrapper.find('[data-action="check-username"]');
    const $submitPassword = $wrapper.find('[data-action="submit"]');
    const $usernameForm = $wrapper.find('#username-form');
    const $backForm = $wrapper.find('#back-form');
    const $backButton = $wrapper.find('.btn-back');

    $usernameForm.on('submit', () => {
      hideError();

      const username = $wrapper.find('#UserUsername').val();

      if (!username) {
        showError('Please enter a username or email address.');
        return false;
      }

      $submitUsername.html('Loading...');
      $submitUsername.addClass('disabled');

      return true;
    });

    $backButton.on('click', () => {
      $submitPassword.html('Loading...');
      $wrapper.find('.btn-login').addClass('disabled');
      $backForm.submit();
    });

    $submitPassword.on('click', (e) => {
      e.preventDefault();

      const $submitPasswordButton = $(e.currentTarget);

      hideError();

      const $password = $wrapper.find('#UserPassword');
      const value = $password.val();
      if (!value) {
        showError('Please enter a password.');
        return;
      }

      const userData = {};
      userData['data[User][password]'] = value;
      userData['data[User][username]'] = $wrapper.find('#username').attr('data-username');
      userData['csrf_token'] = $wrapper.find('[name="csrf_token"]').val();

      let qs = '';
      if (window.location.search) {
        qs = window.location.search;
      }

      $submitPasswordButton.html('Loading...');
      $wrapper.find('.btn-login').addClass('disabled');

      $.post(`/users/login${qs}`, userData, (data) => {
        if (data.response.error) {
          showError(data.response.error);

          $password.val('');
          $submitPasswordButton.html('Log In');
          $wrapper.find('.btn-login').removeClass('disabled');

          return;
        }

        if (data.response.redirect) {
          window.location.href = data.response.redirect;
        }
      });
    });
  });
}

export { init };
